<template>
  <transition name="fade-in-up">
    <div
      v-show="!hidden"
      ref="card"
      @click="moveTo(link)"
    >
      <slot />
    </div>
  </transition>
</template>

<script>
import eventBus from '@/js/util/eventBus'

export default {
  props: {
    link: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      hidden: false
    }
  },
  mounted () {
    const dot = this.$el.getElementsByClassName('partner-card__dot')[0]
    const corner = this.$el.getElementsByClassName('partner-card__corner')[0]
    dot.style.color = dot.dataset.color
    corner.style.backgroundColor = corner.dataset.bg

    eventBus.$on('partner-card-hide', this.hideCard)
    eventBus.$on('partner-card-show', this.showCard)
  },
  methods: {
    moveTo (link) {
      window.location.href = link
    },

    hideCard (card) {
      if (this.$el === card) {
        this.hidden = true
      }
    },

    showCard (card) {
      if (this.$el === card) {
        this.hidden = false
      }
    }
  }
}
</script>

<style lang="scss">
.fade-in-up-enter,
.fade-in-down-leave-to {
  transform: translateY(25px);
  opacity: 0;
}

.fade-in-up-enter-active,
.fade-in-up-leave-active {
  transition: all 0.5s ease;
}
</style>
