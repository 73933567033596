<template>
  <div
    v-if="show"
    class="product-card col-6 col-md-4"
    :class="paged ? 'product__on-page': ''"
  >
    <div class="product-card__inner">
      <div class="product-card__image">
        <img
          class="bgphoto"
          :src="productData.card_image"
          :alt="productData.card_alt"
        >
      </div>

      <div class="product-card__top">
        <div class="product-card__top-plus">
          <span />
          <span />
        </div>
      </div>

      <div class="product-card__info">
        <div class="product-card__info-inner">
          <div class="product-card__info-top">
            <h3 class="h5">
              {{ productData.post_title }}
            </h3>

            <p
              v-if="productData.book_options_short_summary"
              class="product-card__info-summary p--sm"
            >
              {{ productData.book_options_short_summary }}
            </p>
            <p
              v-else
              class="product-card__info-summary p--sm"
            >
              {{ productData.product_info_product_description }}
            </p>
          </div>
          <div class="product-card__info-bottom">
            <a
              :href="fullSlug(productData)"
              class="cta cta--color-parchment cta--hover-atlantic"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/js/util/eventBus'

export default {
  props: {
    productData: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      show: true,
      paged: false
    }
  },

  computed: {

  },

  mounted () {
    eventBus.$on('update-shop-filters', this.updateFilters)
    eventBus.$on('fuzzy-shop', (args) => {
      if (args[0] === this.productData.id) {
        // console.log('in ' + this.productData.id + ' args check')
        this.show = args[1]
      }
    })
    eventBus.$on('update-shop-page', this.updatePage)
  },

  methods: {
    fullSlug (product) {
      let slugPrefix
      if (product.post_type === 'zando_books') {
        slugPrefix = '/books/'
      } else {
        slugPrefix = '/shop/'
      }
      return slugPrefix + product.slug
    },

    updateFilters (filters) {
      let filterArray = Object.keys(filters).map(function (key) {
        return filters[key]
      })
      // console.log(filterArray)

      let elements = [ this.productData.post_type ]
      if (this.productData.book_options_catalyst_partner) {
        const cp = [ this.productData.book_options_catalyst_partner ]
        elements = [ ...elements, ...cp ]
      }
      if (this.productData.book_options_genre) {
        elements = [ ...elements, ...this.productData.book_options_genre ]
      }
      // console.log(elements)

      var temp = filterArray.filter(function (value) {
        return value !== ''
      })

      if (temp.every(f => elements.includes(f))) {
        this.show = true
      } else {
        this.show = false
      }
    },

    updatePage (pageNum) {
      const siblings = this.$el.parentElement.children
      const elIndex = Array.from(siblings).indexOf(this.$el)

      const start = (pageNum - 1) * 6
      const end = pageNum * 6

      if (elIndex >= start && elIndex < end) {
        this.paged = true
      } else {
        this.paged = false
      }
    }
  }
}
</script>
