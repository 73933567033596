<template>
  <div class="news-filter__select">
    <button
      ref="dropdownButton"
      class="news-filter__select-button"
      aria-haspopup="listbox"
      @click="clicked = !clicked"
      @blur="checkFocus"
    >
      <span>Sort</span>
      <img
        src="/wp-content/themes/zando-2021/src/images/carat-black.svg"
        alt=""
      >
    </button>
    <transition name="fade-in-down">
      <ul
        v-show="clicked"
        class="news-filter__listbox"
        role="listbox"
      >
        <li role="option">
          <a
            class="news-filter__option"
            href="/news"
            @blur="checkFocus"
          >
            All News
          </a>
        </li>
        <li
          v-for="(link, index) in JSON.parse(links)"
          :key="index"
          role="option"
          :class="{'news-filter__notempty' : link.count }"
        >
          <a
            class="news-filter__option"
            :href="'/news/?tag=' + link.slug"
            @blur="checkFocus"
          >
            {{ link.name }}
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      clicked: false
    }
  },
  mounted () {
    document.body.addEventListener('click', (event) => {
      if (!event.target.classList.contains('news-filter__option') && event.target !== this.$refs.dropdownButton) {
        this.clicked = false
      }
    })

    window.addEventListener('keyup', this.handleEsc)
  },
  methods: {
    checkFocus (e) {
      let current = e.relatedTarget
      if (current !== null) {
        if (!current.classList.contains('news-filter__option') && current !== this.$refs.dropdownButton) {
          this.clicked = false
        }
      }
    },
    handleEsc (e) {
      if ((e.keyCode === 27) & this.clicked) {
        this.clicked = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-in-down-enter-active,
.fade-in-down-leave-active {
  transition: all .25s ease;
}

.fade-in-down-enter,
.fade-in-down-leave-to {
  transform: translateY(-25px);
  opacity: 0;
}
</style>
