<template>
  <div class="card-carousel">
    <VueSlickCarousel
      ref="carousel"
      v-bind="settings"
      class="book-grid__carousel"
      @beforeChange="updateActive"
    >
      <slot />

      <template #customPaging="page">
        <button
          class="card-carousel__dot"
          :aria-label="'Move to slide ' + (page + 1)"
        >
          <span />
        </button>
      </template>
    </VueSlickCarousel>

    <div class="card-carousel__controls container">
      <div class="card-carousel__controls-flex">
        <div class="card-carousel__controls-button card-carousel__controls-prev">
          <button
            aria-label="Move to previous slide"
            @click="$refs.carousel.prev()"
          >
            <svg
              width="68px"
              height="68px"
              viewBox="0 0 68 68"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              class="svg-carat"
            >
              <g
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <polygon
                  class="svg-carat__fill"
                  fill="#00001c"
                  transform="translate(34.000000, 34.000000) rotate(-45.000000) translate(-34.000000, -34.000000) "
                  points="52.3721894 10 58 58 10 52.3721894"
                />
              </g>
            </svg>
          </button>
        </div>

        <div class="card-carousel__controls-dots">
          <ul>
            <li
              v-for="card in numSlides"
              :key="card"
            >
              <button
                :aria-label="`Move to slide ${card} ${currentSlide}`"
                :class="currentSlide == (card - 1) ? 'active' : ''"
                @click="$refs.carousel.goTo(card - 1)"
              />
            </li>
          </ul>
        </div>

        <div class="card-carousel__controls-button card-carousel__controls-next">
          <button
            aria-label="Move to next slide"
            @click="$refs.carousel.next()"
          >
            <svg
              width="68px"
              height="68px"
              viewBox="0 0 68 68"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              class="svg-carat"
            >
              <g
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <polygon
                  class="svg-carat__fill"
                  fill="#00001c"
                  transform="translate(34.000000, 34.000000) rotate(-45.000000) translate(-34.000000, -34.000000) "
                  points="52.3721894 10 58 58 10 52.3721894"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  components: {
    VueSlickCarousel
  },
  props: {
    numSlides: {
      default: 0,
      type: Number,
      required: true
    }
  },
  data () {
    return {
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        swipeToSlide: true,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '30px',
        variableWidth: true,
        initialSlide: 0
      },
      currentSlide: 0
    }
  },
  methods: {
    updateActive (oldActive, newActive) {
      this.currentSlide = newActive
    }
  }
}
</script>
