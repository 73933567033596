<template>
  <div class="shop__list">
    <div
      ref="list"
      class="row shop__list-items"
    >
      <slot />
    </div>

    <nav
      v-if="numItems > 0"
      class="events__paging pagination"
      aria-label="Events pagination"
    >
      <div class="pagination__flex">
        <div
          v-if="currentPage !== 1"
          class="pagination-prev"
        >
          <a
            href="#"
            aria-label="Previous"
            @click="pagePrev"
          >
            <svg
              width="68px"
              height="68px"
              viewBox="0 0 68 68"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              class="svg-carat"
            >
              <g
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <polygon
                  class="svg-carat__fill"
                  fill="#00001c"
                  transform="translate(34.000000, 34.000000) rotate(-45.000000) translate(-34.000000, -34.000000) "
                  points="52.3721894 10 58 58 10 52.3721894"
                />
              </g>
            </svg>
          </a>
        </div>
        <div
          v-else
          class="pagination-prev inactive"
        >
          <svg
            width="68px"
            height="68px"
            viewBox="0 0 68 68"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            class="svg-carat"
          >
            <g
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <polygon
                class="svg-carat__fill"
                fill="#00001c"
                transform="translate(34.000000, 34.000000) rotate(-45.000000) translate(-34.000000, -34.000000) "
                points="52.3721894 10 58 58 10 52.3721894"
              />
            </g>
          </svg>
        </div>

        <ul class="pagination-links">
          <li
            v-for="index in numPages"
            :key="index"
            class="pagination-link"
          >
            <a
              href="#"
              :class="[currentPage == index ? 'current' : '', 'page-number']"
              @click="updatePage(index, $event)"
            >
              {{ index }}
            </a>
          </li>
        </ul>

        <div
          v-if="currentPage != numPages"
          class="pagination-next"
        >
          <a
            href="#"
            aria-label="Next"
            @click="pageNext"
          >
            <svg
              width="68px"
              height="68px"
              viewBox="0 0 68 68"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              class="svg-carat"
            >
              <g
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <polygon
                  class="svg-carat__fill"
                  fill="#00001c"
                  transform="translate(34.000000, 34.000000) rotate(-45.000000) translate(-34.000000, -34.000000) "
                  points="52.3721894 10 58 58 10 52.3721894"
                />
              </g>
            </svg>
          </a>
        </div>
        <div
          v-else
          class="pagination-next inactive"
        >
          <svg
            width="68px"
            height="68px"
            viewBox="0 0 68 68"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            class="svg-carat"
          >
            <g
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <polygon
                class="svg-carat__fill"
                fill="#00001c"
                transform="translate(34.000000, 34.000000) rotate(-45.000000) translate(-34.000000, -34.000000) "
                points="52.3721894 10 58 58 10 52.3721894"
              />
            </g>
          </svg>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import eventBus from '@/js/util/eventBus'

export default {
  data () {
    return {
      numItems: 0,
      numPages: 0,
      currentPage: 1
    }
  },
  mounted () {
    this.updatePaging()
    eventBus.$on('shop-pagination', this.updatePaging)
  },
  methods: {
    updatePaging () {
      setTimeout(() => {
        this.numItems = this.$refs.list.childElementCount
        this.numPages = Math.floor(this.numItems / 6)
        if (this.numPages === 0 || this.numItems % 6 !== 0) {
          this.numPages = this.numPages + 1
        }
        this.currentPage = 1
        eventBus.$emit('update-shop-page', this.currentPage)

        eventBus.$emit('update-shop-count', this.numItems)
      }, 300)
    },

    pageNext (event) {
      event.preventDefault()
      this.currentPage = this.currentPage + 1
      eventBus.$emit('update-shop-page', this.currentPage)
    },
    pagePrev (event) {
      event.preventDefault()
      this.currentPage = this.currentPage - 1
      eventBus.$emit('update-shop-page', this.currentPage)
    },
    updatePage (pageNum, event) {
      event.preventDefault()
      this.currentPage = pageNum
      eventBus.$emit('update-shop-page', this.currentPage)
    }
  }
}
</script>
