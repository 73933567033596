<template>
  <div
    class="hero-animation"
    :class="{ 'hero-animation__played' : animationComplete }"
  >
    <lottie-animation
      :animation-data="NamePlateData"
      :loop="false"
      :auto-play="true"
      class="desktop-only"
      @complete="animationComplete = true"
    />
    <lottie-animation
      :animation-data="NamePlateDataResponsive"
      :loop="false"
      :auto-play="true"
      class="mobile-only"
      @complete="completeAnimation()"
    />
  </div>
</template>

<script>
import eventBus from '@/js/util/eventBus'
import LottieAnimation from 'lottie-web-vue'
import { NamePlateData } from '@/js/components/HeroAnimation/namePlateData'
import { NamePlateDataResponsive } from '@/js/components/HeroAnimation/namePlateDataResponsive'

export default {
  components: {
    LottieAnimation
  },
  data () {
    return {
      animationComplete: false,
      NamePlateData,
      NamePlateDataResponsive
    }
  },
  mounted () {
    eventBus.$emit('nav:loading', true)
  },
  methods: {
    completeAnimation () {
      this.animationComplete = true
      eventBus.$emit('nav:loading', false)
    }
  }
}
</script>
