<template>
  <div aria-live="polite">
    <slide-up-down :active="expanded">
      <slot />
    </slide-up-down>

    <button
      :aria-expanded="expanded.toString()"
      class="accordion-button label"
      @click="expanded = !expanded"
    >
      <span
        v-if="!expanded"
      >
        Read More
      </span>
      <span
        v-if="expanded"
      >
        Read Less
      </span>
      <img
        src="/wp-content/themes/zando-2021/src/images/carat-black.svg"
        alt=""
        :class="{ expanded: expanded }"
      >
    </button>
  </div>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down'
export default {
  components: {
    SlideUpDown
  },
  data () {
    return {
      expanded: false
    }
  }
}
</script>
