<template>
  <div>
    <VueSlickCarousel
      ref="backgroundCarousel"
      v-bind="bgSettings"
      class="hero-carousel__bg"
      @beforeChange="syncSliders"
    >
      <slot name="backgrounds" />
    </VueSlickCarousel>

    <div
      class="hero-carousel__controls"
      :class="[numSlides == 1 ? 'hero-carousel__single' : '']"
    >
      <div class="hero-carousel__controls-inner">
        <div
          v-if="numSlides > 1"
          class="nav-icons"
        >
          <button
            type="button"
            class="slide-indicator--prev"
            @click="$refs.backgroundCarousel.prev()"
          >
            <svg
              height="42"
              viewBox="0 0 26 42"
              width="26"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon
                points="26,0 26,42 0,21"
                fill="#f7f7f0"
              />
            </svg>
          </button>
          <button
            v-for="i in numSlides"
            :key="i - 1"
            type="button"
            :class="i - 1 != currentSlide ? 'slide-indicator' : 'slide-indicator slide-indicator--active'"
            @click="$refs.backgroundCarousel.goTo(i - 1)"
          />
          <button
            type="button"
            class="slide-indicator--next"
            @click="$refs.backgroundCarousel.next()"
          >
            <svg
              height="42"
              viewBox="0 0 26 42"
              width="26"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon
                points="0,0 0,42 26,21"
                fill="#f7f7f0"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: {
    VueSlickCarousel
  },
  props: {
    numSlides: {
      type: Number,
      default: 0
    },
    autoAdvance: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      bgSettings: {
        arrows: false,
        dots: false,
        infinite: true,
        lazyLoad: 'ondemand',
        fade: true,
        swipeToSlide: true,
        slidesToShow: 1,
        autoplaySpeed: 5000
      },
      textSettings: {
        arrows: false,
        dots: false,
        infinite: true,
        fade: true,
        slidesToShow: 1,
        swipeToSlide: true,
        autoplaySpeed: 5000
      },
      currentSlide: 0
    }
  },
  methods: {
    syncSliders (currentPosition, nextPosition) {
      this.currentSlide = nextPosition
      this.$refs.backgroundCarousel.goTo(nextPosition)
    }
  }
}
</script>
