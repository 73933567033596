<template>
  <div class="events-index">
    <div class="events-search container">
      <div class="row">
        <form class="search-wrapper col-xs-12 col-md-8">
          <input
            ref="search"
            type="text"
            placeholder="Search Events"
            class="p"
          >
          <button
            type="submit"
            class="cta cta--color-parchment cta--bg-ultramarine"
            @click="filterCards"
          >
            Search
          </button>
        </form>

        <div class="filter-wrapper filter-wrapper__sort col-xs-12 col-md-4">
          <div class="select-wrapper">
            <select
              id="sort"
              class="p"
              @change="sortEvents"
            >
              <option
                value=""
                disabled
              >
                Sort By
              </option>
              <option
                v-for="(option, key, index) in sortOptions"
                :key="index"
                :value="key"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <button
        v-if="optionBool(filterOptions.month_filter) || optionBool(filterOptions.location_filter) || optionBool(filterOptions.organizer_filter)"
        class="cta cta--color-ink col-xs-12 col-md-6 filter-toggle"
        @click="toggleFilters"
      >
        Filters
      </button>

      <transition name="fade">
        <div
          v-show="showFilters & (optionBool(filterOptions.month_filter) || optionBool(filterOptions.location_filter) || optionBool(filterOptions.organizer_filter))"
          class="filter__row row"
        >
          <div
            v-show="optionBool(filterOptions.month_filter)"
            class="filter-wrapper col-xs-12 col-md-4"
          >
            <label
              for="date"
              class="label filter-label"
            >
              Month
            </label>
            <div class="select-wrapper">
              <select
                id="date"
                ref="month"
                class="p"
                @change="updateFilters"
              >
                <option value="">
                  All months
                </option>
                <option
                  v-for="(month, index) in months"
                  :key="index"
                  :value="month"
                  v-html="monthFormat(month)"
                />
              </select>
            </div>
          </div>

          <div
            v-show="optionBool(filterOptions.location_filter)"
            class="filter-wrapper col-xs-12 col-md-4"
          >
            <label
              for="locaton"
              class="label filter-label"
            >
              Location
            </label>
            <div class="select-wrapper">
              <select
                id="location"
                ref="location"
                class="p"
                @change="updateFilters"
              >
                <option value="">
                  All locations
                </option>
                <option
                  v-for="(location, index) in locations"
                  :key="index"
                  :value="location"
                >
                  {{ location }}
                </option>
              </select>
            </div>
          </div>

          <div
            v-show="optionBool(filterOptions.organizer_filter)"
            class="filter-wrapper col-xs-12 col-md-4"
          >
            <label
              for="organizer"
              class="label filter-label"
              disabled
            >
              Organizer
            </label>
            <div class="select-wrapper">
              <select
                id="organizer"
                ref="organizer"
                class="p"
                @change="updateFilters"
              >
                <option value="">
                  All organizers
                </option>
                <option
                  v-for="(organizer, index) in organizers"
                  :key="index"
                  :value="organizer"
                >
                  {{ organizer }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <button
          v-if="filtered"
          class="filter-clear label"
          @click="clearFilters"
        >
          Clear filters
        </button>
      </transition>
    </div>

    <event-paging>
      <event-item
        v-for="(event, index) in sorted"
        :key="index"
        :event-data="event"
        :index="index"
      />

      <p
        v-if="visibleItems === 0"
        class="p col-12"
      >
        No items match your search.
      </p>
    </event-paging>
  </div>
</template>
<script>
import eventBus from '@/js/util/eventBus'
import FuzzySearch from 'fuzzy-search'

export default {
  props: {
    events: {
      type: Array,
      default: () => []
    },
    filterOptions: {
      type: Object,
      default: () => []
    }
  },
  data () {
    return {
      filters: { date: '', location: '', organizer: '' },
      sortOptions: { event_date: 'Event Date', post_title: 'Title', event_cp_name: 'Catalyst Partner' },
      sorted: this.events,
      showFilters: false,
      filtered: false,
      visibleItems: 0
    }
  },

  computed: {
    locations () {
      let locationArray = []
      this.events.forEach((item) => {
        if (!locationArray.includes(item.event_location)) {
          locationArray.push(item.event_location)
        }
      })
      locationArray.sort()
      return locationArray
    },

    organizers () {
      let organizerArray = []
      this.events.forEach((item) => {
        if (!organizerArray.includes(item.event_organizer)) {
          organizerArray.push(item.event_organizer)
        }
      })
      organizerArray.sort()
      return organizerArray
    },

    months () {
      let monthArray = []
      this.events.forEach((item) => {
        if (!monthArray.includes(item.event_date.substring(0, 6))) {
          monthArray.push(item.event_date.substring(0, 6))
        }
      })
      monthArray.sort()
      return monthArray
    },

    sortedEvents: {
      get: function () {
        return this.sorted
      },
      // setter
      set: function (sortBy) {
        this.sorted = this.events.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1))
      }
    }
  },
  mounted () {
    if (window.innerWidth >= 768) {
      this.showFilters = true
    }
    // eventBus.$on('updatePageNum', this.updatePageNum)
    this.fuzzyList = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.events.length; ++i) {
      this.fuzzyList.push({
        title: this.events[i].post_title,
        organizer: this.events[i].event_organizer,
        date: this.events[i].event_date,
        partner: this.events[i].event_cp_name,
        index: i
      })
    }
    // threshold: 0.0 requires exact match, 1.0 will match to anything
    this.fuzzySearch = new FuzzySearch(this.fuzzyList, ['title', 'organizer', 'date', 'partner'])

    eventBus.$on('update-event-count', (count) => {
      this.visibleItems = count
    })
  },
  methods: {
    optionBool (option) {
      if (option === 'true') return true
      else return false
    },

    updateFilters (e) {
      this.filters[e.target.id] = e.target.value
      this.checkFilters()
      eventBus.$emit('update-filters', this.filters)
      eventBus.$emit('pagination')
    },

    checkFilters () {
      let filtered = 0
      for (let index in this.filters) {
        if (this.filters[index] !== '') filtered = 1
      }

      if (filtered === 1) this.filtered = true
      else this.filtered = false
    },

    sortEvents (e) {
      this.sortedEvents = e.target.value
      eventBus.$emit('pagination')
    },

    filterCards (e) {
      if (e) {
        e.preventDefault()
      }
      if (this.$refs.search.value === '') {
        // eslint-disable-next-line no-return-assign
        // eslint-disable-next-line no-param-reassign
        this.sorted.forEach((item) => { item.show = true })
        eventBus.$emit('pagination')
        return
      } else {
        this.filtered = true
      }

      // only show cards that match fuzzy search
      this.results = this.fuzzySearch.search(this.$refs.search.value)
      // console.log(this.results)
      this.sorted.forEach((item) => {
        // check if results match card title + date
        eventBus.$emit(
          'fuzzy-events',
          [
            item.id,
            this.results.some(e => e.title === item.post_title && e.date === item.event_date)
          ]
        )

        eventBus.$emit('pagination')
      })
    },

    toggleFilters () {
      this.showFilters = !this.showFilters
    },

    clearFilters () {
      this.$refs.search.value = ''
      this.filterCards()

      this.$refs.month.value = ''
      this.filters['date'] = ''

      this.$refs.location.value = ''
      this.filters['location'] = ''

      this.$refs.organizer.value = ''
      this.filters['organizer'] = ''

      eventBus.$emit('update-filters', this.filters)
      this.filtered = false
    },

    monthFormat (date) {
      date = date.toString()
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
      const month = date.substring(4, 6)
      const monthText = months[parseInt(month - 1)]

      return monthText + ' ' + date.substring(0, 4)
    }
  }
}

</script>
<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
