/*
|--------------------------------------------------------------------------
| Entry File
|--------------------------------------------------------------------------
|
| This is the main entry file used by Webpack. As such, we use it to
| include CSS as well as the usual JS. The order is important as
| CSS rules ar affected by specificity. All styles loaded in
| App.vue and its child files will of course be included.
|
*/

// Styles
import '@/styles/main.scss'

// Vue
import Vue from 'vue'
// import App from './App.vue'

// Import components
import NavBar from '@/js/components/Navbar/NavBar.vue'
import NavButton from '@/js/components/Navbar/NavButton.vue'
import NavMenu from '@/js/components/Navbar/NavMenu.vue'
import MainContent from '@/js/components/Navbar/MainContent.vue'

import HeroAnimation from '@/js/components/HeroAnimation/HeroAnimation.vue'
import HeroCarousel from '@/js/components/HeroCarousel.vue'

import EventItem from '@/js/components/Events/EventItem.vue'
import EventPaging from '@/js/components/Events/EventPaging.vue'
import EventSearch from '@/js/components/Events/EventSearch.vue'

import ShopItem from '@/js/components/Shop/ShopItem.vue'
import ShopPaging from '@/js/components/Shop/ShopPaging.vue'
import ShopSearch from '@/js/components/Shop/ShopSearch.vue'

import Accordion from '@/js/components/Accordion.vue'
import CardCarousel from '@/js/components/CardCarousel.vue'
import CardGrid from '@/js/components/CardGrid.vue'
import GlobalAnimations from '@/js/components/GlobalAnimations.vue'
import NewsFilterDropdown from '@/js/components/NewsFilterDropdown.vue'
import PartnerCard from '@/js/components/PartnerCard.vue'
import PartnerHero from '@/js/components/PartnerHero.vue'
import ProductGallery from '@/js/components/ProductGallery.vue'
import ThirdPartyDropdown from '@/js/components/ThirdPartyDropdown.vue'

import ModalVideo from '@/js/components/modal-video/index'

const Components = {
  NavBar,
  NavButton,
  NavMenu,
  MainContent,

  HeroAnimation,
  HeroCarousel,

  EventItem,
  EventPaging,
  EventSearch,

  ShopItem,
  ShopPaging,
  ShopSearch,

  Accordion,
  CardCarousel,
  CardGrid,
  GlobalAnimations,
  NewsFilterDropdown,
  PartnerCard,
  PartnerHero,
  ProductGallery,
  ThirdPartyDropdown
}

Vue.use(ModalVideo)

Object.keys(Components).forEach(componentName => {
  Vue.component(componentName, Components[componentName])
})
Vue.config.productionTip = false

new Vue().$mount('#app')
