import eventBus from '@/js/util/eventBus'

export default {
  props: {
    videoId: {
      type: String,
      required: true
    }
  },

  render (createElement) {
    const elementData = {
      on: {
        click: event => {
          event.preventDefault()
          event.stopPropagation()
          eventBus.$emit('toggle:modal', `video-${this.videoId}`)
        }
      },
      attrs: {
        'aria-label': 'Play video',
        id: `modal-open-video-${this.videoId}`
      }
    }
    return createElement('button', elementData, this.$slots.default)
  }
}
