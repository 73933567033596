<template>
  <header
    class="nav__filled"
    :class="{ expanded : expanded }"
    @focusin="handleFocusIn"
  >
    <slot />
  </header>
</template>

<script>
import eventBus from '@/js/util/eventBus'

export default {
  props: {
    onHome: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      expanded: false,
      scrollPos: window.scrollY,
      scrolled: false,
      scrollable: true,
      focused: false
    }
  },
  mounted () {
    eventBus.$on('nav:toggle', this.toggleContent)
    eventBus.$on('nav:loading', this.handleLoading)
    window.addEventListener('scroll', this.handleScroll)

    eventBus.$on('nav:noscroll', this.toggleScrollable) // use this event if there is a pinned animation at the top of the page
  },
  destroyed () {
    window.removeEventListener('scroll')
  },
  methods: {
    toggleContent (toggled) {
      this.expanded = toggled
    },
    handleScroll () {
      if (this.scrollable) {
        var newScroll = window.pageYOffset || this.$el.scrollTop
        if (window.pageYOffset < 100 || newScroll < this.scrollPos) {
          this.$el.classList.remove('hidden')
        } else {
          this.$el.classList.add('hidden')
        }

        this.scrollPos = newScroll
      }
    },
    handleFocusIn () {
      this.$el.classList.remove('hidden')
    },
    toggleScrollable (scrollable) {
      this.scrollable = scrollable
      this.$el.classList.remove('hidden')
    },
    handleLoading (loading) {
      // console.log('handling loading, ' + loading)
      if (loading) {
        this.$el.classList.add('hidden')
      } else {
        this.$el.classList.remove('hidden')
      }
    }
  }
}
</script>
