<template>
  <button
    :class="{ toggled : expanded }"
    aria-label="Toggle navigation menu"
    :aria-expanded="expanded.toString()"
    @click="toggleNav"
    @keydown="handleTab"
  >
    <div
      v-if="!expanded"
      class="nav__button-hamburger"
    >
      <span />
      <span />
      <span />
    </div>
    <div
      v-if="expanded"
      class="nav__button-x"
    >
      <img
        src="/wp-content/themes/zando-2021/src/images/x-icon.svg"
        alt=""
      >
    </div>
  </button>
</template>

<script>
import eventBus from '@/js/util/eventBus'

export default {
  data () {
    return {
      expanded: false,
      navMenuLinks: Array
    }
  },
  mounted () {
    eventBus.$on('nav:collapse', this.collapseNav)
    this.navMenuLinks = document.getElementById('nav-menu').querySelectorAll('a')
  },
  methods: {
    toggleNav () {
      this.expanded = !this.expanded
      eventBus.$emit('nav:toggle', this.expanded)
      this.setBodyScroll()
    },
    collapseNav () {
      this.expanded = false
      eventBus.$emit('nav:toggle', this.expanded)
      this.setBodyScroll()
      this.$el.focus()
    },
    setBodyScroll () {
      document.body.style.overflow = this.expanded ? 'hidden' : 'initial'
    },
    handleTab (e) {
      if (this.expanded && e.which === 9) {
        e.preventDefault()
        if (e.shiftKey) {
          this.navMenuLinks[this.navMenuLinks.length - 1].focus()
        } else {
          this.navMenuLinks[0].focus()
        }
      }
    }
  }
}
</script>
