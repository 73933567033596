<template>
  <transition name="slide">
    <nav
      v-show="expanded"
      id="nav-menu"
      :class="{ expanded: expanded }"
    >
      <button
        ref="button"
        class="nav__button"
        @click="collapseNav"
        @keydown="focusLast"
      >
        <img
          src="/wp-content/themes/zando-2021/src/images/x-icon.svg"
          alt=""
        >
      </button>
      <slot />
    </nav>
  </transition>
</template>

<script>
import eventBus from '@/js/util/eventBus'

export default {
  data () {
    return {
      expanded: false,
      firstLink: Object,
      lastLink: Object
    }
  },
  mounted () {
    eventBus.$on('nav:toggle', this.toggleExpanded)
    window.addEventListener('keyup', this.handleEsc)

    this.firstLink = this.$el.querySelectorAll('a')[0]
    this.lastLink = this.$el.querySelectorAll('a')[this.$el.querySelectorAll('a').length - 1]

    this.firstLink.addEventListener('keydown', (e) => {
      if (e.which === 9 && e.shiftKey) {
        e.preventDefault()
        this.focusButton()
      }
    })

    this.lastLink.addEventListener('keydown', (e) => {
      if (e.which === 9 && !e.shiftKey) {
        e.preventDefault()
        this.focusButton()
      }
    })
  },
  methods: {
    toggleExpanded (toggled) {
      this.expanded = toggled
      this.$nextTick(() => {
        this.focusButton()
      })
    },
    collapseNav () {
      eventBus.$emit('nav:collapse')
    },
    handleEsc (e) {
      if ((e.keyCode === 27) & this.expanded) {
        eventBus.$emit('nav:collapse')
      }
    },
    focusButton () {
      if (this.expanded) {
        if (window.innerWidth < 960) {
          this.$refs.button.focus()
        } else {
          document.getElementById('nav_hamburger').focus()
        }
      }
    },
    focusLast (e) {
      if (e.which === 9 && e.shiftKey) {
        e.preventDefault()
        this.lastLink.focus()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform .5s;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(100vw);
  @media screen and (min-width: 960px) {
    transform: translateX(425px);
  }
}
</style>
