<template>
  <div :class="{ expanded : expanded }">
    <slot />
  </div>
</template>

<script>
import eventBus from '@/js/util/eventBus'

export default {
  data () {
    return {
      expanded: false
    }
  },
  mounted () {
    eventBus.$on('nav:toggle', this.toggleContent)
  },
  methods: {
    toggleContent (toggled) {
      this.expanded = toggled
    }
  }
}
</script>
