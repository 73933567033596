<template>
  <div
    id="shop-feed"
    class="container shop-feed"
  >
    <div class="shop-title">
      <h2 class="h2">
        Shop Zando
      </h2>
    </div>

    <div class="shop-filters">
      <div class="shop-filters__top row no-gutters">
        <div class="col-12 col-lg-6 shop-search__wrapper">
          <form class="shop-search search-wrapper">
            <input
              ref="search"
              type="text"
              placeholder="Search Shop"
              class="p"
            >
            <button
              type="submit"
              class="cta cta--color-parchment cta--bg-ultramarine"
              @click="filterCards"
            >
              Search
            </button>
          </form>
        </div>
        <div class="col-12 col-lg-5 shop-filter__type">
          <button
            :class="filters.type === '' ? 'active' : ''"
            class="h5"
            @click="updateType('')"
          >
            All Products
          </button>
          <button
            :class="filters.type === 'zando_books' ? 'active' : ''"
            class="h5"
            @click="updateType('zando_books')"
          >
            Books
          </button>
          <button
            :class="filters.type === 'zando_shop' ? 'active' : ''"
            class="h5"
            @click="updateType('zando_shop')"
          >
            Merch
          </button>
        </div>
      </div>

      <div class="shop-filter__button">
        <button
          v-if="optionBool(filterOptions.genre_filter) || optionBool(filterOptions.partner_filter)"
          class="cta cta--color-ink filter-toggle col-12"
          @click="showFilters = !showFilters"
        >
          Filters
        </button>
      </div>

      <transition name="fade">
        <div
          v-if="showFilters"
          class="shop-filter__bottom filter__row row"
        >
          <div class="filter-wrapper filter-wrapper__sort col-12 col-md-4">
            <div class="select-wrapper">
              <select
                id="sort"
                ref="sort"
                class="p"
                @change="sortShop"
              >
                <option
                  value=""
                  disabled
                >
                  Sort By
                </option>
                <option
                  v-for="(option, key) in sortOptions"
                  :key="key"
                  :value="key"
                >
                  {{ option }}
                </option>
              </select>
            </div>
          </div>

          <div
            v-show="optionBool(filterOptions.genre_filter)"
            class="filter-wrapper col-12 col-md-4"
          >
            <label
              for="genre"
              class="label filter-label"
            >
              Genre
            </label>
            <div class="select-wrapper">
              <select
                id="genre"
                ref="genre"
                class="p"
                @change="updateFilters"
              >
                <option value="">
                  All Genres
                </option>
                <option
                  v-for="genre in genres"
                  :key="genre.term_id"
                  :value="genre.term_id"
                >
                  {{ genre.name }}
                </option>
              </select>
            </div>
          </div>

          <div
            v-show="optionBool(filterOptions.partner_filter)"
            class="filter-wrapper col-12 col-md-4"
          >
            <label
              for="partner"
              class="label filter-label"
            >
              Partner
            </label>
            <div class="select-wrapper">
              <select
                id="partner"
                ref="partner"
                class="p"
                @change="updateFilters"
              >
                <option value="">
                  All Partners
                </option>
                <option
                  v-for="partner in partners"
                  :key="partner.id"
                  :value="partner.id"
                >
                  {{ partner.post_title }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <button
          v-if="filtered"
          class="filter-clear label"
          @click="clearFilters"
        >
          Clear Filters
        </button>
      </transition>
    </div>

    <shop-paging>
      <shop-item
        v-for="(product, index) in sorted"
        :key="index"
        :product-data="product"
        :index="index"
      />

      <p
        v-if="visibleItems === 0"
        class="p col-12"
      >
        No items match your search.
      </p>
    </shop-paging>
  </div>
</template>

<script>
import eventBus from '@/js/util/eventBus'
import FuzzySearch from 'fuzzy-search'

export default {
  props: {
    products: {
      type: Array,
      default: () => []
    },
    partners: {
      type: Array,
      default: () => []
    },
    genres: {
      type: Array,
      default: () => []
    },
    filterOptions: {
      type: Object,
      default: () => []
    }
  },
  data () {
    return {
      filters: { type: '', genre: '', partner: '' },
      sortOptions: { post_date: 'Date added', post_title: 'Title', book_options_author: 'Author' },
      sorted: this.products,
      showFilters: false,
      filtered: false,
      visibleItems: 0
    }
  },

  computed: {
    sortedProducts: {
      get: function () {
        return this.sorted
      },
      // setter
      set: function (sortBy) {
        if (sortBy === 'post_date') {
          this.sorted = this.products.sort((a, b) => (a[sortBy] < b[sortBy] ? 1 : -1))
        } else {
          this.sorted = this.products.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1))
        }
      }
    }
  },

  mounted () {
    if (window.innerWidth >= 768) {
      this.showFilters = true
    }

    this.fuzzyList = []
    for (let i = 0; i < this.products.length; i++) {
      this.fuzzyList.push({
        title: this.products[i].post_title,
        author: this.products[i].book_options_author
      })
    }
    this.fuzzySearch = new FuzzySearch(this.fuzzyList, ['title', 'author', 'partner'])

    this.$nextTick(() => {
      this.$refs.sort.dispatchEvent(new Event('change'))

      if (this.findGetParam('genre')) {
        this.$refs.genre.value = this.findGetParam('genre')
        this.$refs.genre.dispatchEvent(new Event('change'))
        this.$el.scrollIntoView()
      }
    })

    setTimeout(() => {
      if (this.findGetParam('type')) {
        this.updateType(this.findGetParam('type'))
        this.$el.scrollIntoView()
      }
    }, 350)

    eventBus.$on('update-shop-count', (count) => {
      this.visibleItems = count
    })
  },

  methods: {
    optionBool (option) {
      if (option === 'true') return true
      else return false
    },

    findGetParam (parameterName) {
      let result = null
      let tmp = []
      window.location.search
        .substr(1)
        .split('&')
        .forEach(function (item) {
          tmp = item.split('=')
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
        })
      return result
    },

    updateFilters (e) {
      this.filters[e.target.id] = e.target.value
      this.checkFilters()
      eventBus.$emit('update-shop-filters', this.filters)
      eventBus.$emit('shop-pagination')
    },

    updateType (type) {
      this.filters['type'] = type
      this.checkFilters()
      eventBus.$emit('update-shop-filters', this.filters)
      eventBus.$emit('shop-pagination')
    },

    sortShop (e) {
      this.sortedProducts = e.target.value
      eventBus.$emit('shop-pagination')
    },

    filterCards (e) {
      if (e) {
        e.preventDefault()
      }
      if (this.$refs.search.value === '') {
        // eslint-disable-next-line no-return-assign
        // eslint-disable-next-line no-param-reassign
        this.sorted.forEach((item) => { item.show = true })
        eventBus.$emit('shop-pagination')
      } else {
        this.filtered = true
      }

      let results = this.fuzzySearch.search(this.$refs.search.value)
      // console.log(results)

      this.sorted.forEach((item) => {
        if (item.post_type === 'zando_books') {
          eventBus.$emit(
            'fuzzy-shop',
            [ item.id,
              results.some(e => e.title === item.post_title) ||
              results.some(e => e.author === item.book_options_author) ]
          )
        } else {
          eventBus.$emit(
            'fuzzy-shop',
            [
              item.id,
              results.some(e => e.title === item.post_title)
            ]
          )
        }

        eventBus.$emit('shop-pagination')
      })
    },

    checkFilters () {
      let filtered = 0
      for (let index in this.filters) {
        if (this.filters[index] !== '') filtered = 1
      }

      if (filtered === 1) this.filtered = true
      else this.filtered = false
    },

    clearFilters () {
      this.$refs.search.value = ''
      this.filterCards()

      this.filters['type'] = ''

      this.$refs.genre.value = ''
      this.filters['genre'] = ''

      this.$refs.partner.value = ''
      this.filters['partner'] = ''

      eventBus.$emit('update-shop-filters', this.filters)
      this.filtered = false
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
