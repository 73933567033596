<template>
  <button
    class="bg-video-control"
    :aria-label="playing ? 'Pause background video' : 'Play background video'"
    @click="toggleBgVideo()"
  >
    <img
      v-if="playing"
      :src="`${path}/src/images/icon/pause.svg`"
      alt=""
    >
    <img
      v-if="!playing"
      :src="`${path}/src/images/icon/play.svg`"
      alt=""
    >
  </button>
</template>

<script>
import eventBus from '@/js/util/eventBus'

export default {
  props: {
    playerId: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      playing: true
    }
  },
  mounted () {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
    if (mediaQuery.matches) {
      this.playing = false
    }
  },
  methods: {
    toggleBgVideo () {
      this.playing = !this.playing
      eventBus.$emit('toggle:video', `${this.playerId}`)
    }
  }
}
</script>
