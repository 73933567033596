<template>
  <div>
    <slot />

    <div
      v-if="cardsHidden"
      class="card-grid__more"
    >
      <button
        class="cta
        cta--color-ink cta--hover-ultramarine"
        @click="loadMore"
      >
        Load More
      </button>
    </div>
  </div>
</template>

<script>
import eventBus from '@/js/util/eventBus'

export default {
  props: {
    numCards: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      listLimit: 6,
      currentLimit: 6,
      cardsHidden: false,
      cardChildren: []
    }
  },
  mounted () {
    if (this.numCards > this.listLimit) {
      this.cardChildren = this.$el.querySelectorAll('.partner-card')
      this.cardsHidden = true
      this.hideExtra(this.listLimit)
    }
  },
  methods: {
    hideExtra (limit) {
      // console.log('limit: ' + limit)
      for (let i = limit; i < this.numCards; i++) {
        eventBus.$emit('partner-card-hide', this.cardChildren[i])
      }
    },

    updateShown () {
      for (let i = (this.currentLimit - 1); i >= 0; i--) {
        // console.log('cycling? ' + i)
        eventBus.$emit('partner-card-show', this.cardChildren[i])
      }
    },

    loadMore () {
      this.currentLimit = this.currentLimit + this.listLimit
      // console.log('new limit: ' + this.currentLimit)

      if (this.currentLimit >= this.numCards) {
        this.cardsHidden = false
        for (let card of this.cardChildren) {
          eventBus.$emit('partner-card-show', card)
        }
      } else {
        this.cardsHidden = true // shouldn't change, just in case???
        this.updateShown()
      }
    }
  }
}
</script>
