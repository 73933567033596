<template>
  <section>
    <slot />
  </section>
</template>

<script>
export default {
  data () {
    return {
      atTop: true
    }
  },

  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    if (window.pageYOffset < 100) {
      document.getElementById('navbar').classList.remove('nav__filled')
    }
  },

  destroyed () {
    window.removeEventListener('scroll')
  },

  methods: {
    handleScroll () {
      if (window.pageYOffset < 100) {
        document.getElementById('navbar').classList.remove('nav__filled')
      } else {
        document.getElementById('navbar').classList.add('nav__filled')
      }
    }
  }
}
</script>
