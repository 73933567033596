<template>
  <ModalContainer :modal-id="`video-${id}`">
    <div class="video-modal__video-wrapper">
      <ModalButton
        :id="`modal-close-video-${id}`"
        :modal-id="`video-${id}`"
        class="ModalButton ModalButton--close"
        aria-label="Close Video"
      />
      <PlayerLoader
        :player-id="`video-${id}`"
        :url="url"
        :background-video="false"
        use-modal
      />
    </div>
  </ModalContainer>
</template>

<script>
// import FocusTrap from '@/js/components/FocusTrap'

export default {
  components: {
    // FocusTrap,
  },
  props: {
    id: {
      type: String,
      default: '1'
    },
    url: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      modalActive: false
    }
  }
}
</script>
