<template>
  <div
    v-if="show"
    class="featured-events__item event col-xs-12 col-md-6"
    :class="paged ? 'event__on-page' : ''"
  >
    <div class="featured-events__inner">
      <div class="featured-events__text">
        <p
          id="event_date"
          class="h5 featured-events__date"
        >
          {{ format_date(eventData.event_date) }}
        </p>
        <p class="h5 featured-events__time">
          {{ format_time(eventData.event_time) }}
        </p>
        <p
          id="event_organizer"
          class="h5 featured-events__organizer"
        >
          {{ eventData.event_organizer }}
        </p>

        <p
          v-if="eventData.event_cp"
          id="event_cp"
          class="h5 featured-events__cp"
          :style="`color: ${eventData.event_cp_color}`"
        >
          {{ eventData.event_cp_name }}
        </p>

        <a
          class="featured-events__link"
          :href="eventData.event_link"
          target="_blank"
        >
          <p class="h4 featured-events__description">
            {{ eventData.post_title }}
          </p>
        </a>
      </div>
      <div class="arrow-accent">
        <div class="featured-events__item-cta" />
        &nbsp;•
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/js/util/eventBus'

export default {
  props: {
    eventData: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      show: true,
      paged: false
    }
  },

  mounted () {
    eventBus.$on('update-filters', this.updateFilters)
    eventBus.$on('update-page', this.updatePage)

    eventBus.$on('fuzzy-events', (args) => {
      if (args[0] === this.eventData.id) {
        this.show = args[1]
      }
    })
  },
  methods: {

    updateFilters (filters) {
      let filterArray = Object.keys(filters).map(function (key) {
        return filters[key]
      })

      /* filterArray[0] = filterArray[0].replaceAll('-', '')
      filterArray[0] = filterArray[0].substring(0, 6) */

      const elements = [this.eventData.event_date.substring(0, 6), this.eventData.event_location, this.eventData.event_organizer]

      /* filterArray.forEach((filter, index) => {
        if (filter === '') {
          elements.splice(index, 1)
        }
      }) */
      // DZ note: idk what the above did but it was prevent filter from working?

      // console.log(elements)
      var temp = filterArray.filter(function (value) {
        return value !== ''
      })
      // console.log(temp)

      if (temp.every(f => elements.includes(f))) {
        this.show = true
      } else {
        this.show = false
      }
    },

    format_date (value) {
      const month = value.substring(4, 6)
      const day = value.substring(6, 8)
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
      const monthText = months[parseInt(month - 1)]

      return `${day} ${monthText}`
    },

    format_time (value) {
      let hour = parseInt(value.substring(0, 2))
      let d = 'AM'
      if (hour >= 12) {
        hour = hour - 12
        d = 'PM'
      }
      if (hour === 0) {
        hour = 12
      }

      let minute = (value.substring(3, 5))
      return `${hour}:${minute}${d}`
    },

    updatePage (pageNum) {
      const siblings = this.$el.parentElement.children
      const elIndex = Array.from(siblings).indexOf(this.$el)

      const start = (pageNum - 1) * 10
      const end = pageNum * 10

      if (elIndex >= start && elIndex < end) {
        this.paged = true
      } else {
        this.paged = false
      }
    }

    /* watch: {
      id: function (newVal, oldVal) { // watch it
        console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      }
    } */
  }
}
</script>
