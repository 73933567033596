<template>
  <div />
</template>

<script>
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
  mounted () {
    ScrollTrigger.batch('.partner-card', {
      start: 'top 65%',
      end: 'bottom 25%',
      // markers: true,
      onEnter: this.addCardClass
      /* onEnterBack: this.addCardClass,
      onLeave: this.removeCardClass,
      onLeaveBack: this.removeCardClass */
    })
  },
  methods: {
    addCardClass (batch) {
      gsap.to(batch, {
        stagger: {
          each: 0.25,
          onStart: function () {
            this.targets()[0].classList.add('partner-card__scrolled')

            setTimeout(() => {
              this.targets()[0].classList.add('partner-card__hoverable')
            }, 1000)
          }
        }
      })
    }

    // Preserving in case you need to have it set to hide on scroll away again
    /* removeCardClass (batch) {
      gsap.to(batch, {
        stagger: {
          each: 0.25,
          onStart: function () {
            this.targets()[0].classList.remove('partner-card__scrolled')
          }
        }
      })
    } */
  }
}
</script>
