<template>
  <div class="thirdparty-select">
    <button
      ref="dropdownButton"
      class="thirdparty-select__button"
      aria-haspopup="listbox"
      @click="clicked = !clicked"
      @blur="checkFocus"
    >
      <span>Buy From</span>
      <img
        src="/wp-content/themes/zando-2021/src/images/carat-black.svg"
        alt=""
        :class="{ expanded: clicked }"
      >
    </button>
    <transition name="fade-in-down">
      <ul
        v-show="clicked"
        class="thirdparty-select__listbox"
        role="listbox"
      >
        <li
          v-for="(link, index) in JSON.parse(links)"
          :key="index"
          role="option"
        >
          <a
            class="thirdparty-select__option"
            :href="link.product_link"
            target="_blank"
            @blur="checkFocus"
          >
            {{ link.store_name }}
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      clicked: false
    }
  },
  mounted () {
    document.body.addEventListener('click', (event) => {
      if (!event.target.classList.contains('thirdparty-select__option') && event.target !== this.$refs.dropdownButton) {
        this.clicked = false
      }
    })

    window.addEventListener('keyup', this.handleEsc)
  },
  methods: {
    checkFocus (e) {
      let current = e.relatedTarget
      if (current !== null) {
        if (!current.classList.contains('thirdparty-select__option') && current !== this.$refs.dropdownButton) {
          this.clicked = false
        }
      }
    },
    handleEsc (e) {
      if ((e.keyCode === 27) & this.clicked) {
        this.clicked = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-in-down-enter-active,
.fade-in-down-leave-active {
  transition: all .25s ease;
}

.fade-in-down-enter,
.fade-in-down-leave-to {
  transform: translateY(-25px);
  opacity: 0;
}
</style>
