<template>
  <div class="product-info__gallery-carousels">
    <VueSlickCarousel
      ref="mainImage"
      v-bind="mainSettings"
      class="product-info__gallery-main"
    >
      <slot name="main" />
    </VueSlickCarousel>
    <div
      ref="imageSelector"
      class="product-info__gallery-selector"
    >
      <div
        v-for="(image, index) in JSON.parse(images)"
        :key="index"
        class="product-info__gallery-image"
      >
        <img
          :src="image.url"
          :alt="image.alt"
          @click="$refs.mainImage.goTo(index)"
        >
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: {
    VueSlickCarousel
  },
  props: {
    images: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      mainSettings: {
        arrows: false,
        dots: false,
        infinite: false,
        lazyLoad: 'ondemand',
        fade: true,
        swipeToSlide: true,
        slidesToShow: 1
      }
    }
  }
}
</script>
